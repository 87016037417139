<template>
  <div class="footer">
    <div class="footer-content">
      <div class="left">
        <img src="/static/web-logo.png" alt="doyoudo" />
        <p>do what you love and master it!</p>
      </div>
      <div class="right">
        <a href="" target="_blank">关于我们</a>
        <a href="https://support.qq.com/products/302506/" target="_blank"
          >帮助中心</a
        >
        <a href="" target="_blank">加入我们</a>
        <a href="" target="_blank">用户协议</a>
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
          >京ICP备15058894号-1</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>

<style scoped>
.footer {
  background: #fff;
  margin: 0 auto;
  font-size: 14px;
}

.footer-content {
  height: 120px;
  width: 1228px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.footer a {
  margin-left: 20px;
  cursor: pointer;
}
.footer img {
  width: 100px;
  vertical-align: middle;
  margin-bottom: 10px;
}

.footer a:hover {
  color: rgb(149, 182, 28);
}

@media screen and (max-width: 470px) {
  .footer {
    width: 100%;
  }

  .footer-content {
    height: 120px;
    width: 100%;
    padding: 20px;
    font-size: 0.8em;
  }
  .left {
    margin-right: 30px;
  }
  .footer a {
    display: inline-block;
    margin: 8px 15px;
  }
  .footer img {
    width: 80px;
    vertical-align: middle;
    margin-bottom: 5px;
  }
}
@media screen and (min-width: 471px) and (max-width: 1024px) {
  .footer {
    width: 100%;
  }

  .footer-content {
    height: 100px;
    width: 100%;
    padding: 20px 40px;
    font-size: 0.8em;
  }
  .left {
    margin-right: 30px;
  }
  .footer a {
    display: inline-block;
    margin: 4px 15px;
  }
  .footer img {
    width: 80px;
    vertical-align: middle;
    margin-bottom: 5px;
  }
}
</style>