<template>
  <div class="nav">
    <ul class="nav-list">
      <li>
        <router-link to="/" class="logo">
          <span class="test">beta</span>
          <img
            src="https://wechatapppro-1252524126.file.myqcloud.com/app45DDvGw38268/image/b_u_5b7457ba21ff8_i0DEvgEY/l0mgvydk0kiy.png"
            alt=""
          />
        </router-link>
      </li>
      <li>
        <router-link to="/">首页</router-link>
      </li>
      <li>
        <router-link to="/free">免费课程</router-link>
      </li>
      <li>
        <a href="https://premium.doyoudo.com/" target="_blank">付费课程</a>
      </li>

      <!-- <li>
        <router-link to="/Alert">付费课程</router-link>
      </li> -->
      <li>
        <router-link to="/cop">外部合作课</router-link>
      </li>
      <li>
        <router-link to="/resources">资源导航 </router-link>
      </li>
      <li>
        <router-link to="/learningpath">学习路径 </router-link>
      </li>
      <li>
        <a href="https://support.qq.com/products/302506/" target="_blank"
          >帮助社区</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TheNav",
};
</script>

<style scoped>
.logo img {
  width: 120px;
  vertical-align: middle;
}

.logo {
  position: relative;
  margin-right: 20px;
}

.test {
  font-size: 0.7em;
  position: absolute;
  top: -10px;
  right: -14px;
  color: tomato;
}
.nav-list {
  width: 1240px;
  margin: 0 auto;
  /* max-width: 1220px; */
}

.nav {
  width: 100%;
  height: 72px;
  background: #fff;
  margin-bottom: 35px;
}

.nav .nav-list {
  display: -webkit-flex; /* Safari */
  display: flex;
  line-height: 72px;
}
a {
  display: inline-block;
}
.nav .nav-list li {
  margin: 0 16px;
  cursor: pointer;
  font-size: 14px;
}

.nav .nav-list .router-link-active {
  color: #95b312;
}

.nav .nav-list a:hover {
  color: #95b312;
}

@media screen and (max-width: 470px) {
  .nav .nav-list {
    width: 100%;
    line-height: 40px;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-left: 8px;
  }

  .nav {
    width: 100%;
    height: 100px;
  }

  .nav .nav-list li {
    margin: 0 11px;
    align-items: center;
  }
  .logo img {
    width: 90px;
  }

  .logo {
    position: relative;
  }
}
@media screen and (min-width: 471px) and (max-width: 1024px) {
  .nav .nav-list {
    width: 100%;
    line-height: 40px;
    flex-wrap: wrap;
    padding-top: 15px;
    padding-left: 8px;
  }

  .nav .nav-list li {
    margin: 0 15px;
  }
  .logo img {
    width: 95px;
  }
}

/* ------------------------------- */
</style>