<template>
  <div class="content">
    <router-view />
  </div>
</template>



<style scoped>
.content {
  margin: 0 auto;
  /* max-width: 1220px; */
}
</style>
